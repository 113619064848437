@import 'src/styles/variables';

.wrapper {
    display: flex;

    .inputContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        border: 1px solid #d8dee7;
        padding: 12px 8px;
        border-radius: 8px;
        position: relative;
        width: 100%;
        height: 40px;

        &.focused {
            border-color: $lavanda;
        }

        input {
            width: calc(100% - 26px);
            border: none;
            outline: none;
            color: #0f2337;
            background: none;

            &::placeholder {
                color: #bcbec0;
            }
        }

        .clearInput {
            position: absolute;
            right: 10px;
            font-size: 13px;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #bcbec0;
            font-style: normal;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .revealContainer {
        display: flex;
        align-content: center;

        &:hover {
            cursor: pointer;
        }
    }
}
