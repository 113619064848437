@import 'src/styles/variables';

.view {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fbfbfd;
    padding: 0 20px 40px 20px;
    gap: 14px;

    .table {
        display: flex;
        flex-direction: column;
        width: 100%;
        box-shadow: $box-shadow-with-border;
        border-radius: 8px;
        overflow: hidden;

        .searchSection {
            height: 50px;
            background-color: #f0f0f0;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0 10px;

            input {
                height: 34px;
                background-color: #00000014;
                border-radius: 6px;
                outline: none;
                border: none;
                padding: 0 10px;
            }
        }

        .tableHead {
            background-color: #f0f0f0;
            height: 30px;
            display: flex;
            align-items: center;
            padding: 0 14px;
            border-top: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            gap: 10px;

            span {
                flex-grow: 1;
                font-size: 14px;
                font-weight: 500;
                color: #000000a3;
            }
        }

        .row {
            display: flex;
            align-items: flex-start;
            gap: 14px;
            flex-grow: 1;
            overflow-wrap: anywhere;
            padding: 20px 10px;
            background-color: white;

            &:not(:last-child) {
                border-bottom: 1px solid #d8dee7;
            }
        }

        .col {
            display: flex;
            align-items: center;
            flex-grow: 1;
            font-size: 14px;
        }

        .key {
            min-width: 160px;
            max-width: 160px;
            color: #000;
            gap: 10px;
        }

        .value {
            width: 100%;
        }

        .updatedBy {
            max-width: 240px;
            min-width: 240px;
        }
        .actions {
            display: flex;
            justify-content: center;
            gap: 20px;
            min-width: 60px;

            .editIcon,
            .deleteIcon:hover {
                cursor: pointer;
            }
        }
    }

    .deletionModal {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        gap: 14px;

        img {
            width: 80px;
        }
    }
}
