@import 'src/styles/variables';

#basicInfo {
    display: flex;
    gap: 20px;

    .avatarContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: fit-content;
        width: fit-content;
        border-radius: 50%;
        border: 2px solid white;
        box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
    }

    label {
        font-size: 16px;
        font-weight: bold;
        color: $dark-font-color;
        margin-bottom: 6px;
    }

    .row {
        display: flex;
        gap: 14px;
    }

    .inputContainer {
        margin-bottom: 20px;
    }
}
