@import 'src/styles/variables';

.subscriptions {
    display: flex;
    flex-direction: column;
    position: relative;

    h3 {
        color: $dark-font-color;
        padding-bottom: 8px;
    }

    .noSubscription {
        border: 2px dashed #d8dee7;
        border-radius: 6px;
        width: 100%;
        color: #6c7688;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px 0;
    }

    table {
        white-space: nowrap;
        vertical-align: top;
        border-spacing: 0;
        table-layout: auto;
        width: 100%;
        border-collapse: collapse;
        color: #6c7688;
        font-size: 14px;

        tr {
            border-bottom: 1px solid #ebeef1;
        }

        th {
            color: $dark-font-color;
            text-align: left;
            font-size: 12px;
            width: auto;
            padding: 8px 16px 8px 0;
        }

        td {
            padding: 8px 16px 8px 0;
        }

        .status {
            margin-left: 16px;
            width: fit-content;
            padding: 0 8px;
            font-size: 12px;
            border-radius: 3px;
            font-weight: 500;
        }

        .moreDetailsContainer {
            display: flex;
            justify-content: flex-end;

            .moreDetailsIcon {
                display: flex;
                align-items: center;
                width: fit-content;
                padding: 4px 8px;
                background: white;
                box-shadow: $box-shadow-with-border;
                border-radius: 4px;
                transition: box-shadow 0.25s;

                svg {
                    pointer-events: none;
                }

                &:hover {
                    cursor: pointer;
                    box-shadow: $box-shadow-with-border-hover;
                }
            }
        }
    }
}
