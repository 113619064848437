@import 'src/styles/variables';

.view {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fbfbfd;
    padding: 0 20px 40px 20px;
    gap: 14px;

    .creationButtons {
        display: flex;
        gap: 20px;

        button {
            padding: 10px;
            background-color: $lavanda;
            color: white;
            border-radius: 4px;
            border: none;
            outline: none;
        }
    }
}
