#notFound {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-image: url('../../assets/img/space.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    h1,
    p {
        color: white;
        text-shadow: 0 2px 5px rgba(255, 255, 255, 0.5);
    }

    h1 {
        font-size: 8em;
    }

    p {
        font-size: 2em;
        text-align: center;
    }
}
