@import 'src/styles/variables';

.container {
    display: flex;
    gap: 16px;

    .option {
        display: flex;
        gap: 10px;
        align-items: center;

        span {
            color: $dark-font-color;
        }

        input[type='radio'] {
            display: flex;
            justify-content: center;
            align-items: center;
            appearance: none;
            background: none;
            margin: 0;
            width: 16px;
            height: 16px;
            box-shadow: rgb(0 0 0 / 0%) 0 0 0 0, rgb(0 0 0 / 0%) 0 0 0 0,
                rgb(0 0 0 / 12%) 0 2px 2px 0, rgb(64 68 82 / 16%) 0 0 0 2px,
                rgb(0 0 0 / 0%) 0 0 0 0, rgb(0 0 0 / 0%) 0 0 0 0,
                rgb(64 68 82 / 8%) 0 2px 5px 0;
            border-radius: 50%;

            &:checked {
                background: $lavanda;
                box-shadow: rgb(0 0 0 / 0%) 0 0 0 0,
                    rgb(1 150 237 / 36%) 0 0 0 4px, rgb(0 0 0 / 12%) 0 1px 1px 0,
                    rgb(99 91 255) 0 0 0 1px, rgb(0 0 0 / 0%) 0 0 0 0,
                    rgb(0 0 0 / 0%) 0 0 0 0, rgb(64 68 82 / 8%) 0 2px 5px 0;
            }

            &::after {
                content: '';
                width: 6px;
                height: 6px;
                border-radius: 50%;
                transform: scale(0);
                transition: 0.24s transform ease-in-out;
                box-shadow: inset 1em 1em #fff;
            }

            &:checked::after {
                transform: scale(1);
            }
        }
    }
}
