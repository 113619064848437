@import 'src/styles/variables';

.wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;

    .fade {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }

    .modal {
        display: flex;
        flex-direction: column;
        background-color: white;
        position: relative;
        max-width: 600px;

        .buttons {
            display: flex;
            height: 48px;
            justify-content: flex-end;
            align-items: center;
            padding: 0 20px;
            gap: 12px;

            button {
                background: none;
                outline: none;
                border: none;
                border-radius: 6px;
                height: fit-content;
                padding: 6px 12px;
                font-weight: 500;
                display: flex;
                align-items: center;
                gap: 8px;
            }

            .discardButton {
                box-shadow: $box-shadow-with-border;
                color: $dark-font-color;
            }

            .confirmButton {
                color: white;
                background-color: #7358ff;
                &.loading {
                    background-color: rgba(115, 88, 255, 0.75);
                }
            }
        }
    }
}
