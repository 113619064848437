@font-face {
    font-family: Poppins;
    src: url(assets/fonts/Poppins-Light.ttf);
    font-weight: 300;
}

@font-face {
    font-family: Poppins;
    src: url(assets/fonts/Poppins-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: Poppins;
    src: url(assets/fonts/Poppins-Medium.ttf);
    font-weight: 500;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Poppins;
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: #fbfbfd;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

button {
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        cursor: pointer;
    }
}

/**
This is to remove the up-down arrows on inputs of type: number
 */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
