@import 'src/styles/variables';

.subscriptionPopoverOverlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    inset: 0;

    &.shown {
        display: flex;
    }
}

.subscriptionMoreDetailsPopover {
    background: white;
    box-shadow: $box-shadow-with-border;
    position: absolute;
    right: 15px;
    border-radius: 6px;
    opacity: 0;
    visibility: hidden;

    &.shown {
        opacity: 1;
        visibility: visible;
    }

    ul {
        list-style: none;
        padding: 8px 0;
        display: flex;
        flex-direction: column;

        .separator {
            background-color: #d8dee7;
            height: 1px;
            width: 100%;
            margin: 8px 0;
        }

        li {
            font-size: 14px;
            padding: 4px 8px;
            height: 28px;
            user-select: none;

            a {
                color: $lavanda-text;
                font-weight: 500;

                &.danger {
                    color: $soft-red;
                }
            }

            &.disabled {
                pointer-events: none;

                a {
                    color: $disabled-color;
                    font-weight: 500;
                }
            }

            &:hover {
                cursor: pointer;
                background-color: $soft-hover;

                & a {
                    color: rgba(26, 27, 37);
                }
            }
        }
    }
}
