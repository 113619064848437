@import 'src/styles/variables';

.content {
    display: flex;
    flex-direction: column;
    padding: 18px;
    gap: 14px;
    width: 400px;
    margin-bottom: 20px;

    h3 {
        margin-bottom: 13px;
    }

    .formElement {
        display: flex;
        flex-direction: column;
        gap: 4px;
        flex: 1;
    }

    label {
        font-size: 12px;
        font-weight: 500;
    }

    .formRow {
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }

    .invalidForm {
        font-size: 14px;
        font-weight: 300;
        color: $soft-red;
    }
}
