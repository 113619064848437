@import 'src/styles/variables';

.form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: fit-content;

    .block {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .formLabel {
            font-size: 16px;
            font-weight: bold;
            color: $medium-font-color;
        }

        .inputLink,
        .inputInfo {
            color: $lavanda;
            font-size: 12px;
        }
        .inputLink:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }

    .tableRow {
        display: flex;
        gap: 20px;
    }

    .createCouponButton {
        bottom: 0;
        width: 100%;
        height: 40px;
        background-color: $lavanda;
        color: white;
        border-radius: 4px;
        font-weight: 400;
        font-size: 16px;
        border-bottom: 3px solid #4a35bb;

        &:disabled {
            cursor: not-allowed;
            background-color: $light-shadow;
            border-bottom: none;
        }
    }
}
