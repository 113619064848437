@import 'src/styles/variables';

.wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;

    .fade {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
    }

    .modal {
        display: flex;
        flex-direction: column;
        background-color: white;
        position: relative;

        form {
            padding: 14px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            h3 {
                font-size: 16px;
            }

            .row {
                display: flex;
                justify-content: space-between;
                gap: 10px;
                margin: 10px 0;
            }

            .fieldContainer {
                display: flex;
                flex-direction: column;
                gap: 4px;

                label {
                    font-size: 12px;
                    color: #969ea6;
                }
            }

            input {
                height: 32px;
                padding: 0 5px;
                border-radius: 4px;
                border: 2px solid #7358ff;
                outline: none;
            }

            input[type='number'] {
                width: 60px;
            }
        }

        .buttons {
            display: flex;
            height: 40px;

            button {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-grow: 1;
                background: none;
                text-decoration: underline;
                color: $dark-font-color;

                &:not(.active):hover {
                    background-color: rgba(0, 0, 0, 0.05);
                }

                &.active {
                    text-decoration: none;
                    background-color: #7358ff;
                    color: white;
                }
            }
        }
    }
}
