@import 'src/styles/variables';

.view {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fbfbfd;
    padding: 0 20px 40px 20px;
    gap: 14px;

    form {
        max-width: 420px;
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        gap: 20px;
    }

    .formField {
        display: flex;
        flex-direction: column;
        gap: 14px;
        background-color: white;
        padding: 20px;
        border-radius: 12px;
        box-shadow: 2px 2px 10px $light-shadow;

        .formFieldTitle {
            display: flex;
            align-items: center;
            gap: 10px;
            color: $dark-font-color;

            .iconWrapper {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #d5f7db;
            }
        }

        .formFieldDescription {
            font-size: 14px;
            color: $medium-font-color;
            padding-bottom: 14px;
            border-bottom: 1px solid $light-border-color;
        }
    }

    .successModal {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        gap: 14px;

        img {
            width: 80px;
        }
    }
}
