.container {
    border: 1px dashed #c0c8d2;
    background-color: #edeff3;
    padding: 4px 8px;
    border-radius: 6px;
    display: flex;
    align-items: center;

    .content {
        display: flex;
        align-items: center;
        gap: 6px;
    }

    span {
        color: #5c636b;
        font-size: 14px;
        user-select: none;
    }

    input[type='file'] {
        display: none;
    }

    .closeIcon:hover {
        cursor: pointer;
        stroke: #fa6c6c;
    }
}
