.wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    padding: 14px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid #d8dee7;
    width: fit-content;

    .iconContainer {
        background-color: #ffecd9;
        border-radius: 6px;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            cursor: pointer;
        }
    }

    .dateRange {
        display: flex;
        flex-direction: column;
        height: 40px;
        justify-content: space-between;
        user-select: none;

        &:hover {
            cursor: pointer;
        }

        h4 {
            font-size: 12px;
            color: #5c636b;
            letter-spacing: 0.5px;
        }

        .dates {
            display: flex;
            gap: 5px;
            color: #0f2337;
            font-size: 14px;
            font-weight: 600;
        }
    }

    .datepicker {
        width: 308px;
        position: absolute;
        background-color: white;
        padding: 14px;
        gap: 14px;
        border-radius: 14px;
        display: flex;
        flex-direction: column;
        outline: none;
        top: 76px;
        left: 0;
        box-shadow: 2px 2px 6px #d3d5dc;
        z-index: 99;

        header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h5 {
                font-size: 12px;
                user-select: none;
                color: #0f2337;
            }

            .buttonsContainer {
                display: flex;
                gap: 6px;

                .buttonWrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    border: 1px solid #d8dee7;
                }
            }

            svg:hover {
                cursor: pointer;
            }
        }

        .daysOfWeekList {
            display: flex;
            list-style: none;
            font-size: 12px;
            justify-content: space-between;
            width: 100%;
            height: fit-content;

            li {
                width: 40px;
                display: flex;
                justify-content: center;
                user-select: none;
                color: #0f2337;
            }
        }

        .daysContainer {
            display: flex;
            flex-wrap: wrap;
        }

        .sendDatesButton {
            width: 100%;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #7358ff;
            color: white;
            border-radius: 6px;
            font-size: 14px;
            margin-top: 12px;
            user-select: none;

            &:hover {
                cursor: pointer;
            }

            &.disabled {
                cursor: not-allowed;
                pointer-events: none;
                background-color: #d3d3fd;
            }
        }
    }
}
