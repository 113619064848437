@import 'src/styles/variables';

.container {
    position: relative;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 10px;
    background-color: #7358ff;
    color: white;
    border-radius: 4px;
    border: none;
    outline: none;
    gap: 10px;

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        background-color: #78869b !important;

        &:hover {
            cursor: not-allowed;
        }
    }

    .icon {
        transform: rotate(90deg);
    }
}

.periodList {
    position: absolute;
    top: 45px;
    list-style: none;
    background-color: white;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    z-index: 99;
    border-radius: 6px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    width: 100%;

    li {
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 8px 0;

        &:hover {
            cursor: pointer;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #edeff3;
        }
    }
}

.toast {
    position: fixed;
    bottom: -80px;
    padding: 10px 20px;
    border-radius: 6px;
    background-color: #fbfbfd;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    color: $dark-font-color;
    transition: bottom 0.5s;
    gap: 12px;

    &.shown {
        bottom: 20px;
    }
}
