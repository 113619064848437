@import 'src/styles/variables';

.goBack {
    color: $medium-font-color;
    margin-bottom: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $medium-font-color;
    width: fit-content;
    height: 20px;
    cursor: pointer;
}
