@import 'styles/variables';

.view {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fbfbfd;
    padding: 0 20px 40px 20px;
    gap: 14px;

    .mainButtonsContainer {
        width: 100%;
        display: flex;
        gap: 20px;
    }
}
