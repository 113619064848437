@import 'src/styles/variables';

.card {
    display: flex;
    align-items: flex-start;
    gap: 20px;
    min-width: 260px;
    padding: 14px;
    border-radius: 16px;
    box-shadow: 2px 2px 6px $light-shadow;
    position: relative;
    background: white; /*linear-gradient(
        15deg,
        rgba(233, 245, 243, 1) 30%,
        rgba(253, 233, 231, 1) 100%
    );*/

    img {
        width: 40px;
        z-index: 1;
    }

    .details {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
        z-index: 1;
        color: #3e1e5d;
        height: 100%;

        .amount {
            font-size: 20px;
            font-weight: bold;
        }

        .moreDetails {
            margin-top: 4px;
            color: #7358ff;
            font-weight: 600;
        }
    }

    .status {
        width: 14px;
        height: 14px;
        border-radius: 50%;
        position: relative;

        .expandedColor {
            content: '';
            position: absolute;
            width: 14px;
            height: 14px;
            transform: scale(1);
            opacity: 1;
            border-radius: 50%;
            animation: ripple 1s infinite;
        }

        .tooltip {
            position: absolute;
            background-color: rgba(0, 0, 0, 0.7);
            padding: 5px;
            color: white;
            font-size: 12px;
            bottom: 20px;
            right: -20px;
            border-radius: 6px;
            display: none;
        }

        &:hover .tooltip {
            display: flex;
        }
    }
}

@keyframes ripple {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}
