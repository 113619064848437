.flex-column {
    display: flex;
    flex-direction: column;
}

.flex {
    display: flex;
}

.center {
    justify-content: center;
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.space-between {
    justify-content: space-between;
}

.view-filtering-section {
    display: flex;
    gap: 10px;
    padding-top: 10px;
    border-top: 1px solid #d8dee7;
    align-items: center;

    .filtering-label {
        color: #414552;
        font-size: 14px;
    }
}
