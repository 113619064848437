@import 'src/styles/variables';

.wrapper {
    h3 {
        color: $dark-font-color;
        margin-bottom: 20px;
    }

    .noInvoices {
        color: $disabled-color;
    }

    .list {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 20px;
        list-style: none;

        li {
            display: flex;
            width: fit-content;
            flex-direction: column;
            justify-content: space-between;
            padding: 14px;
            background-color: white;
            border: 1px solid $light-border-color;
            border-radius: 6px;
            height: 90px;

            .row {
                display: flex;
                gap: 12px;
                justify-content: space-between;
                align-items: center;
            }

            .status {
                width: fit-content;
                padding: 5px 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 20px;
                font-weight: 600;
                font-size: 12px;
            }

            h4 {
                font-size: 16px;
                color: $dark-font-color;
            }

            .date {
                font-size: 12px;
                color: $disabled-color;
            }
        }
    }
}
