.menu {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: white;
    min-width: 240px;
    position: relative;
    justify-content: space-between;

    header {
        gap: 10px;

        h3 {
            font-size: 16px;
        }
    }

    ul {
        list-style: none;
        margin-top: 30px;
    }

    .logoutButton {
        height: 54px;
        width: 100%;
        display: flex;
        justify-content: left;
        align-items: center;
        background-color: white;
        border-top: 1px solid #ddd;
        color: #414552;
        font-size: 14px;
        font-weight: 400;
        gap: 10px;
    }
}
