.basicButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px;
    background-color: #7358ff;
    color: white;
    font-weight: 500;
    font-size: 14px;
    border-radius: 6px;
    border: none;
    outline: none;
    height: 32px;

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        background-color: #78869b !important;

        &:hover {
            cursor: not-allowed;
        }
    }

    .shortcut {
        color: white;
        font-size: 12px;
        font-weight: 500;
        background-color: rgba(255, 255, 255, 0.3);
        padding: 0 4px;
        border-radius: 4px;
    }
}
