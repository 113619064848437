@import 'src/styles/variables';

.view {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f5f4fa;
    padding: 0 20px;
    gap: 20px;

    &.dark {
        background-color: #4c525b !important;
    }

    .row {
        width: 100%;
    }

    .cardsContainer {
        display: flex;
        gap: 20px;
    }

    .chartsSection {
        .chartTitle {
            font-weight: 500;
            color: #414552;
        }
    }

    .chartsContainer {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}
