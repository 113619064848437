@import 'src/styles/variables';

.wrapper {
    display: flex;
    background-color: white;
    padding: 14px;
    border-radius: 6px;
    border: 1px solid $light-border-color;
    gap: 20px;

    .inputContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        border: 2px solid #f1f1f3;
        padding: 12px 8px;
        border-radius: 8px;

        input {
            border: none;
            outline: none;
            width: 180px;
            color: $dark-font-color;

            &::placeholder {
                color: #bcbec0;
            }
        }
    }
}
