@import 'src/styles/variables';

.row {
    width: 100%;
    height: 56px;
    font-size: 14px;
    color: $medium-font-color;
    border-top: 1px solid $light-border-color;

    &:hover {
        background-color: #f5f8ff;
        cursor: pointer;
    }

    td {
        padding: 0 10px;

        .status {
            width: fit-content;
            padding: 5px 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            font-weight: 600;
            font-size: 12px;
        }
    }
}
