@import 'src/styles/variables';

.view {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
    background-color: #d8dee7;

    section {
        border-radius: 10px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        display: flex;
        background-color: white;
        border: 5px solid white;
        overflow: hidden;

        article {
            background-color: #3c37ff;
            padding: 40px 20px 20px 20px;
            max-width: 320px;
            border-radius: 8px;

            h2 {
                font-size: 14px;
                letter-spacing: 1px;
                color: white;
            }

            h1 {
                color: white;
                font-size: 30px;
                margin-top: 100px;
                margin-bottom: 20px;
            }

            h3 {
                color: rgba(255, 255, 255, 0.8);
                font-weight: 300;
                font-size: 14px;
                line-height: 24px;
            }

            .listContainer {
                width: 100%;
                overflow: hidden;
                margin-top: 60px;

                ul {
                    list-style: none;
                    display: flex;

                    li {
                        display: flex;
                        flex-grow: 1;
                        gap: 10px;
                        align-items: center;
                        color: white;
                        padding: 10px;
                        background-color: rgba(0, 0, 0, 0.1);
                        border-radius: 6px;

                        img {
                            width: 36px;
                            height: 36px;
                            border-radius: 6px;
                            background-color: #78869b;
                        }

                        h4 {
                            font-size: 12px;
                            font-weight: bold;
                        }

                        span {
                            font-size: 11px;
                            font-weight: 300;
                        }
                    }
                }

                .dotsList {
                    margin-top: 16px;
                    display: flex;
                    gap: 8px;
                    align-items: center;
                    justify-content: center;

                    .dot {
                        width: 5px;
                        height: 5px;
                        border-radius: 50%;
                        background-color: rgba(255, 255, 255, 0.3);

                        &.active {
                            background-color: white;
                        }
                    }
                }
            }
        }

        .formWrapper {
            padding: 40px;
            color: $dark-font-color;

            header {
                h1 {
                    margin-bottom: 10px;
                }

                p {
                    font-size: 14px;
                }
            }

            form {
                display: flex;
                flex-direction: column;
                gap: 20px;
                margin-top: 60px;

                .inputContainer {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    label {
                        font-weight: bold;
                        font-size: 12px;
                        letter-spacing: 0.5px;
                        color: #838f9b;
                    }
                }

                button {
                    margin-top: 20px;
                    width: 100%;
                    height: 45px;
                    border-radius: 8px;
                    background-color: #3c37ff;
                    color: white;
                    font-size: 14px;

                    &:disabled {
                        background-color: #eaeff7;
                        color: #78869b;
                        cursor: not-allowed;
                    }
                }
            }

            .errorMessage {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                margin-top: 20px;
                color: #f35f5f;
                background-color: #ffd3d3;
                height: 28px;
                border-radius: 4px;
            }
        }
    }
}
