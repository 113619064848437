@import 'src/styles/variables';

.view {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fbfbfd;
    padding: 0 20px 40px 20px;
    gap: 14px;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .paymentWrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .header,
    .moreDetails {
        background-color: #f4f4f6;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        min-width: 400px;
        gap: 10px;

        .methodIcon {
            width: 60px;
            height: 60px;
            border-radius: 50%;
        }

        .title {
            font-size: 18px;
            font-weight: 500;
            color: $dark-font-color;
        }

        .date {
            font-size: 14px;
            color: #444;
            font-weight: 400;
        }

        .amount {
            font-size: 20px;
            font-weight: 500;
        }
    }

    .statusUpdateOptions {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 10px;

        .statusOption {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: space-between;
            background-color: white;
            gap: 6px;
            box-shadow: $box-shadow-with-border;
            padding: 0 10px;
            height: 36px;
            border-radius: 6px;
            font-size: 14px;
            user-select: none;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .moreDetails {
        font-size: 15px;
        color: $dark-font-color;
    }

    .loaderModal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        justify-content: center;
        align-items: center;

        .modal {
            padding: 20px;
            border-radius: 8px;
            background-color: white;
            display: flex;
            width: fit-content;
        }
    }

    .confirmationModal {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
    }

    .notFound {
        font-size: 24px;
        color: $dark-font-color;
    }

    .iconContainer {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
