@import 'src/styles/variables';

.wrapper {
    display: flex;
    gap: 6px;
    flex: 1;
    border: 1px solid #d8dee7;
    border-radius: 8px;
    width: 100%;
    max-height: 40px;
    padding: 4px 0;

    &.focused {
        border-color: $lavanda;
    }

    input {
        padding: 8px;
        flex: 1;
        outline: none;
        color: #0f2337;
        background: none;
        max-height: 40px;
        border: none;
        border-left: 1px solid #ddd;
        width: 0;

        &::placeholder {
            color: #bcbec0;
        }
    }

    select {
        outline: none;
        border: none;
        color: #0f2337;
        background: none;
        max-height: 40px;
        min-width: 50px;
        text-align: center;
    }
}
