@import 'src/styles/variables';

.view {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fbfbfd;
    padding: 0 20px 40px 20px;
    gap: 14px;
    position: relative;

    section {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .giftCardContainer {
            width: 100%;
            display: flex;
            justify-content: center;
        }

        form {
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: fit-content;

            .block {
                display: flex;
                flex-direction: column;
                gap: 8px;

                label {
                    font-size: 16px;
                    font-weight: bold;
                    color: $medium-font-color;
                }
            }
        }
    }

    .notification {
        position: fixed;
        bottom: -100px;
        left: calc(50% + 240px);
        transition: 1s;
        transform: translateX(calc(-50% - 120px));
        background-color: #0f2337;
        color: white;
        display: flex;
        gap: 10px;
        padding: 14px;
        border-radius: 4px;
        align-items: center;

        &.shown {
            bottom: 20px;
        }
    }
}
