@import 'src/styles/variables';

.header {
    display: flex;
    padding-top: 20px;
    justify-content: space-between;
    align-items: center;

    .welcome {
        display: flex;
        flex-direction: column;

        h1 {
            font-size: 24px;
            color: $dark-font-color;
        }

        h3 {
            font-size: 14px;
            font-weight: 400;
            color: $medium-font-color;
        }
    }

    .thumbnail {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }
}
