@import 'src/styles/variables';

.view {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fbfbfd;
    padding: 0 20px 40px 20px;
    gap: 14px;

    .container {
        display: flex;
        flex-direction: column;

        h2 {
            margin-bottom: 20px;
            color: $medium-font-color;
            font-size: 18px;
        }

        .mainContent {
            display: flex;
            justify-content: center;
            gap: 20px;
        }
    }

    .errorEditingModal {
        padding: 14px;
    }
}
