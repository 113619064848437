@import 'src/styles/variables';

.modal {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: white;
    width: 360px;
    border-radius: 8px;
    padding: 8px;
    opacity: 0;
    box-shadow: 0 0 0 1px #8898aa1a, 0 15px 35px #31315d1a, 0 5px 15px #00000014;
    z-index: 99;
    transform: scale(0);
    transition: all 0.25s;

    &.shown {
        transform: scale(1);
        opacity: 1;
    }

    header {
        display: flex;
        justify-content: space-between;
        height: 30px;
        align-items: center;
        padding: 0 10px;
        user-select: none;

        .navArrow {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            &:hover {
                cursor: pointer;
            }
        }

        h5 {
            font-weight: 500;
            color: $dark-font-color;
            font-size: 14px;
        }
    }

    ul {
        display: flex;
        width: 100%;
        height: 30px;
        user-select: none;

        li {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 1;
            font-size: 12px;
            color: $medium-font-color;
        }
    }

    table {
        border-collapse: collapse;
        user-select: none;

        td {
            width: 40px;
            height: 40px;

            .day {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                font-weight: 500;
                color: $dark-font-color;
                background-color: #f6f8faff;
                border: 1px solid #ebeef1ff;

                &:hover {
                    cursor: pointer;
                    background-color: #ebeef1ff;
                }
            }
        }
    }
}
