.dayItem {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-size: 12px;
    border-radius: 50%;
    user-select: none;
    position: relative;
    color: #0f2337;

    &:hover {
        cursor: pointer;
        background-color: #f5f4fa;
    }

    &.notAllowed {
        color: #ccc;
    }

    &.from {
        background-color: #d3d3fd;
        border-radius: 20px 0 0 20px;
    }

    &.to {
        background-color: #d3d3fd;
        border-radius: 0 20px 20px 0;
    }

    .selected {
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        width: 40px;
        height: 40px;
        background-color: #7358ff;
        border-radius: 50%;
    }

    .interval {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #d3d3fd;
        border-radius: 0;
        width: 40px;
        height: 40px;
    }
}
