@import 'src/styles/variables';

.modal {
    display: flex;
    flex-direction: column;

    h4 {
        color: $dark-font-color;
        padding: 16px;
        font-weight: 500;
    }

    p {
        padding: 16px;
        color: $dark-font-color;
        font-size: 14px;

        .bold {
            font-weight: 500;
        }
    }
}
