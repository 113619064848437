.coupon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    padding: 20px 60px;
    color: black;
    width: 320px;
    height: 160px;
    background-color: lavender;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: calc(50% - 25px);
        width: 50px;
        height: 50px;
        background-color: #fbfbfd;
        border-radius: 50%;
    }

    &:before {
        left: -25px;
    }

    &:after {
        right: -25px;
    }

    .reverseText {
        top: 35%;
        left: 36px;
        position: absolute;
        transform: rotate(-90deg);
        font-weight: bold;
        font-size: 20px;
    }

    .discount {
        width: 150px;
        background-color: black;
        color: #7358ff;
        display: flex;
        justify-content: center;
        font-size: 30px;
        padding: 0 4px;
    }

    .code {
        width: 150px;
        height: 60px;
        position: relative;

        .hiddenValue {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            visibility: hidden;
        }

        h2 {
            font-size: 42px;
            width: fit-content;
            transform-origin: left;
            height: 100%;
        }
    }
}
