.toast {
    left: 50%;
    transform: translateX(-50%);
    position: fixed;
    bottom: -60px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 10px 30px #9a9eab;
    background-color: #5ccdad;
    z-index: 999;
    height: 40px;
    width: fit-content;
    padding: 0 14px;
    border-radius: 6px;
    transition: bottom 0.5s;

    &.shown {
        bottom: 20px;
    }

    span {
        color: white;
    }
}
