@import 'src/styles/variables';

.wrapper {
    h3 {
        color: $dark-font-color;
        display: flex;
        gap: 18px;
        align-items: center;
        margin-bottom: 10px;
    }

    .noVehicles {
        color: $disabled-color;
    }

    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        width: fit-content;

        li {
            color: $dark-font-color;
            display: flex;
            flex-direction: column;
            gap: 4px;
            font-size: 14px;
            padding: 14px 0;

            &:not(:last-child) {
                border-bottom: 1px solid $light-border-color;
            }

            span {
                display: flex;
                gap: 8px;
            }

            i {
                font-style: normal;
                color: $disabled-color;
            }
        }
    }
}
