@import 'src/styles/variables';

.header {
    padding: 12px;
    border-bottom: 1px solid $light-border-color;

    h4 {
        font-weight: 500;
    }
}

.content {
    display: flex;
    flex-direction: column;
    padding: 20px;

    label {
        font-size: 12px;
    }
}
