@import 'src/styles/variables';

.container {
    position: relative;

    .mainButton {
        display: flex;
        gap: 6px;
        padding: 4px 8px;
        color: $dark-font-color;
        font-weight: 500;
        background-color: white;
        border-radius: 6px;
        box-shadow: $box-shadow-with-border;
        transition: 0.2s box-shadow;

        &:hover {
            box-shadow: $box-shadow-with-border-hover;
        }
    }
}
