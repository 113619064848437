$light-gray: #ddd;
$soft-hover: #f6f8fa;
$dark-font-color: #414552;
$medium-font-color: #5c636b;
$disabled-color: #969ea6;
$light-font-color: #ffffff;
$light-border-color: #d8dee7;
$light-shadow: #d3d5dc;
$soft-red: #fa6c6c;
$soft-green: #5ccdad;
$lavanda: #7358ff;
$stripe-color: #635bff;
$lavanda-text: #625afa;

$box-shadow-with-border: 0 0 0 1px rgba(-48, 49, 61, 0.1),
    0 2px 5px 0 rgba(48, 49, 61, 0.1), 0 1px 1.5px 0 #00000012,
    0 1px 2px 0 #00000014, 0 0 0 0 transparent;
$box-shadow-with-border-hover: 0 0 0 1px rgba(-48, 49, 61, 0.1),
    0 2px 5px 0 rgba(48, 49, 61, 0.1), 0 1px 1.5px 0 #00000012,
    0 1px 4px 2px #00000014, 0 0 0 0 transparent;
