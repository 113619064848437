@import 'src/styles/variables';

.container {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    z-index: 999;
    left: 0;
    top: 0;

    .fade {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        left: 0;
        top: 0;
    }

    .modal {
        display: flex;
        flex-direction: column;
        background-color: white;
        height: 100%;
        position: relative;
        right: 0;
        top: 0;
        padding: 20px;

        h4 {
            color: $dark-font-color;
            font-size: 24px;
        }

        .row {
            display: flex;
            gap: 10px;
            flex-grow: 1;
        }

        .col {
            display: flex;
            flex-direction: column;
            gap: 4px;
            flex-grow: 1;
        }

        label {
            font-size: 13px;
            color: #666;
            font-weight: 300;
        }

        form {
            display: flex;
            flex-direction: column;
            gap: 20px;
            min-width: 400px;
            margin-top: 40px;

            input {
                padding: 0 10px;
                height: 40px;
                border: 1px solid #d8dee7;
                border-radius: 6px;
                width: 100%;
            }

            textarea {
                resize: none;
                padding: 10px;
                height: 140px;
                border: 1px solid #d8dee7;
                border-radius: 6px;

                &.invalid {
                    border: 1px solid $soft-red !important;
                    outline: 1px solid $soft-red !important;
                }
            }

            .typeOption {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 10px;
            }
        }
    }
}
