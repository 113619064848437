@import 'src/styles/variables';

.wrapper {
    display: flex;
    flex-direction: column;

    .invoice {
        display: flex;
        flex-direction: column;
        padding: 20px;
        background-color: white;
        width: 400px;
        box-shadow: 2px 20px 50px $light-shadow;
        border-radius: 8px;

        h5 {
            color: $disabled-color;
            font-weight: 400;
        }

        h4 {
            color: $dark-font-color;
        }

        .section {
            border-bottom: 3px dotted $light-border-color;
            padding: 14px 0;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            table-layout: auto;

            tbody {
                display: flex;
                flex-direction: column;
                border-bottom: 3px dotted $light-border-color;
                padding: 14px 0;
            }

            tbody.totals {
                tr {
                    justify-content: space-between;
                }
            }

            label {
                color: $disabled-color;
                font-size: 12px;
                font-weight: 300;
            }

            tr {
                display: flex;
                height: 28px;

                td {
                    display: flex;
                    align-items: center;

                    span,
                    b {
                        font-size: 14px;
                    }

                    input {
                        width: 100%;
                        margin-right: 10px;
                    }
                }
            }

            .itemRow {
                .itemDescriptionCol {
                    width: 200px;
                }

                .itemNumberCol,
                .itemActionCol {
                    display: flex;
                    justify-content: flex-end;
                }

                .itemNumberCol {
                    width: 70px;
                }

                .itemActionCol {
                    width: 20px;

                    .itemAction:hover {
                        cursor: pointer;

                        & path {
                            fill: $soft-red;
                        }
                    }
                }
            }

            .addNewItemButton {
                padding: 4px 8px;
                border-radius: 4px;
                background-color: #c1b8ff;
                color: #1f206c;
                gap: 10px;
                height: 28px;
                width: 100%;

                span {
                    font-size: 12px;
                }
            }
        }

        .row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 0;
            gap: 10px;

            label {
                color: $disabled-color;
                font-size: 12px;
                font-weight: 300;
            }

            span,
            b {
                font-size: 14px;
                color: #3e1e5d;
                flex-grow: 2;
            }

            span {
                display: flex;
                align-items: center;
            }

            b {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 6px;
            }

            input {
                width: 50px;
            }

            .itemAction:hover {
                cursor: pointer;

                & path {
                    fill: $soft-red;
                }
            }
        }
    }

    .invoiceButtons {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 14px;

        button {
            width: 400px;
            height: 60px;
            color: white;
            font-size: 16px;
            display: flex;
            align-items: center;
            gap: 10px;
        }

        .updateButton {
            background-color: $lavanda;
        }

        .cancelButton {
            background-color: $soft-red;
        }
    }

    .cancelInvoiceModal {
        display: flex;
        flex-direction: column;
        padding: 14px;

        h3 {
            font-size: 16px;
            margin-bottom: 4px;
        }

        ul {
            margin-top: 10px;
            list-style: disc inside;
        }
    }
}
