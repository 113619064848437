.view {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fbfbfd;
    padding: 0 20px 40px 20px;
    gap: 14px;

    .container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .details {
            display: flex;
            flex-direction: column;
            gap: 30px;
        }
    }
}
