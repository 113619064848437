@import 'src/styles/variables';

.link {
    display: flex;
    align-items: center;
    color: $dark-font-color;
    font-weight: 500;

    &:hover {
        cursor: pointer;

        span {
            text-decoration: underline;
        }
    }

    .icon {
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        margin-left: 6px;
    }
}
