@import 'src/styles/variables';

.view {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fbfbfd;
    padding: 0 20px 40px 20px;
    gap: 14px;

    form {
        display: flex;
        flex-direction: column;
        gap: 14px;
        max-width: 400px;

        label {
            font-size: 18px;
            font-weight: 500;
        }

        .formRow {
            display: flex;
            flex-direction: column;

            p {
                font-size: 14px;
                color: $medium-font-color;
            }
        }
    }
}
