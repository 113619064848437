@import 'src/styles/variables';

.wrapper {
    display: flex;
    flex-direction: column;

    section {
        display: flex;
        gap: 20px;
    }

    .invoice {
        border-radius: 8px;
        box-shadow: 2px 20px 50px $light-shadow;
        width: 400px;
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: white;

        &:after {
            content: '';
            background-image: url('../../assets/img/invoice-border.svg');
            width: 100%;
            height: 40px;
            position: absolute;
            bottom: -10px;
        }

        header {
            display: flex;
            justify-content: space-between;
            padding: 20px;

            h5 {
                color: $disabled-color;
                font-weight: 400;
            }

            h4 {
                color: $dark-font-color;
            }
        }

        .moneyImage {
            align-self: flex-end;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            position: relative;
        }

        .details {
            padding: 20px;
            display: flex;
            flex-direction: column;

            h5 {
                color: $dark-font-color;
                font-weight: 400;
                font-size: 14px;
            }

            .row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 14px 0;
                font-size: 14px;

                &:not(:last-child) {
                    border-bottom: 3px dotted $light-border-color;
                }
            }

            .col {
                display: flex;
                flex-direction: column;
            }

            label {
                color: $disabled-color;
                font-size: 12px;
                font-weight: 300;
            }

            span {
                font-size: 14px;
                font-weight: 400;
                color: #3e1e5d;
                margin-top: 8px;
                width: 100%;
                text-transform: capitalize;

                &.status {
                    padding: 4px 10px;
                    border-radius: 4px;
                    width: fit-content;
                }
            }

            b {
                font-size: 14px;
                color: $dark-font-color;
                margin-top: 8px;
                width: 100%;
                text-align: right;
            }

            .total {
                display: flex;
                align-items: center;
                height: 28px;
            }

            .totalAmount {
                font-size: 18px;
                color: #7358ff;
            }
        }
    }

    .payments {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .actionButtons {
        display: flex;
        width: 400px;
        align-self: center;
        margin-top: 40px;

        button {
            height: 60px;
            background: none;
            color: $medium-font-color;
            font-size: 16px;
            gap: 10px;
            width: 50%;

            &.active {
                background-color: #c1b8ff;
                color: #1f206c;
                /*
        background-color: #f4b073;
        color: #6c431f;
         */
            }
        }
    }
}
