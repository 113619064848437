@import 'src/styles/variables';

.container {
    display: flex;
    position: relative;

    .button {
        height: 24px;
        width: fit-content;
        padding: 0 8px;
        color: #687385;
        font-weight: 400;
        display: flex;
        align-items: center;
        gap: 6px;
        font-size: 12px;
        border: 1px dashed #c0c8d2;
        border-radius: 12px;
        transition: background-color 0.25s;
        user-select: none;

        &:hover {
            background-color: #edeff3;
            cursor: pointer;
        }

        i {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 15px;
            height: 15px;
            border-radius: 50%;
            background-color: #687385;
            color: white;
            font-style: normal;
            font-weight: bold;
            transition: transform 0.2s;

            &.canClear {
                transform: rotate(45deg);
            }
        }

        .valueContainer {
            display: flex;
            align-items: center;
            gap: 6px;

            .separator {
                display: block;
                height: 12px;
                width: 1px;
                background-color: #c0c8d2;
            }

            .value {
                color: #7358ff;
            }
        }
    }

    .filteringModal {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 280px;
        position: absolute;
        top: 30px;
        background-color: white;
        padding: 12px;
        border: 1px solid #f1f1f3;
        border-radius: 6px;
        outline: none;
        box-shadow: 0 0 0 1px #8898aa1a, 0 15px 35px #31315d1a,
            0 5px 15px #00000014;
        visibility: hidden;
        opacity: 0;
        transform: scale(0);
        transition: visibility 0.1s, opacity 0.2s, transform 0.2s;

        &.shown {
            visibility: visible;
            opacity: 1;
            transform: scale(1);
        }

        header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            i {
                font-style: normal;
                color: $disabled-color;
                font-size: 12px;

                &:hover {
                    color: $medium-font-color;
                    cursor: pointer;
                }
            }
        }

        span {
            font-size: 14px;
            display: flex;
            gap: 6px;
            align-items: center;
            color: $medium-font-color;
        }

        input {
            flex-grow: 1;
            border: 1px solid #d8dee7;
            height: 24px;
            border-radius: 4px;
            outline: none;
            padding: 4px 6px;
            font-size: 13px;
            color: rgb(64, 68, 82);

            &:focus {
                border: 2px solid $lavanda;
            }
        }

        button {
            background-color: #7358ff;
            color: white;
            width: 100%;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 6px;
        }
    }
}
