@import 'src/styles/variables';

.view {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fbfbfd;
    padding: 0 20px 40px 20px;
    gap: 14px;

    form {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .formElement {
            display: flex;
            flex-direction: column;
            gap: 20px;

            p {
                font-size: 14px;
                color: $medium-font-color;
            }
        }

        label {
            display: flex;
            gap: 10px;
            align-items: center;

            span {
                font-weight: 500;
                color: $dark-font-color;
                font-size: 18px;
            }
        }

        .planRow {
            display: flex;
            gap: 20px;
        }

        .iconWrapper {
            width: 26px;
            height: 26px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #d5f7db;
        }

        table.plansTable {
            border-collapse: collapse;
            width: 480px;

            td {
                padding: 0 10px;
            }

            .headRow {
                text-transform: uppercase;
                border-top: 1px solid #a4abb5;
                border-bottom: 1px solid #a4abb5;
                height: 36px;

                td {
                    font-size: 13px;
                    color: $dark-font-color;
                    font-weight: 600;
                    text-align: left;

                    &.planName {
                        width: 220px;
                        min-width: 220px;
                        max-width: 220px;
                    }

                    &.planCount {
                        width: 80px;
                        min-width: 80px;
                        max-width: 80px;
                    }

                    &.planTotal {
                        text-align: right;
                        width: 120px;
                        min-width: 120px;
                        max-width: 120px;
                    }

                    &.planActions {
                        width: 60px;
                        min-width: 60px;
                        max-width: 60px;
                    }
                }
            }

            .tableRow {
                height: 68px;
            }
            .footerRow {
                height: 48px;
            }

            .addPlanButton {
                background: none;
                color: $stripe-color;
                font-weight: 500;

                &:hover {
                    color: $dark-font-color;
                }
            }
        }
    }
}
