.view {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fbfbfd;
    padding: 0 20px 40px 20px;
    gap: 14px;

    form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .row {
            display: flex;
            align-items: center;
            gap: 6px;

            .name {
                width: 40px;
            }
            .value {
                width: 80px;
                padding: 0 6px;
                height: 28px;
                border-radius: 6px;
                outline: none;
                border: 1px solid #3c37ff;

                &:disabled {
                    background: #bcc0c7;
                    border: none;
                }
            }
        }
    }
}
