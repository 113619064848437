@import 'src/styles/variables';

.header {
    padding: 12px;
    border-bottom: 1px solid $light-border-color;

    h4 {
        font-weight: 500;
    }
}

.userCreationForm {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 400px;
    padding: 14px;
}

.row {
    display: flex;
    gap: 12px;
    flex: 1;
}

.column {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1;
}

label {
    font-size: 14px;
    font-weight: 500;
}
