@import 'src/styles/variables';

.view {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fbfbfd;
    padding: 0 20px 40px 20px;
    gap: 14px;

    .content {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .couponContainer {
            display: flex;
            justify-content: center;
        }

        .row {
            display: flex;
        }

        form,
        .couponContainer {
            flex-grow: 1;
        }

        form {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .block {
                display: flex;
                flex-direction: column;
                gap: 6px;
            }

            label {
                font-size: 16px;
                font-weight: bold;
                color: $medium-font-color;
            }
        }

        .separator {
            width: 2px;
            height: 100%;
            background-color: $light-border-color;
        }
    }
}
