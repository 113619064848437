@import 'src/styles/variables';

.container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border: 1px solid $light-border-color;
    border-radius: 8px;
    background-color: white;

    footer {
        padding: 14px;
        box-shadow: 0px -2px 8px rgba(179, 184, 190, 0.4);
        display: flex;
        justify-content: flex-end;
        gap: 14px;

        span {
            display: flex;
            align-items: center;
            font-size: 14px;
            color: $medium-font-color;
        }

        .pageButton {
            color: #d5d2f5;
            background-color: #7358ff;
            height: 40px;
            width: 40px;
            border-radius: 6px;

            &.inactive {
                color: #7358ff;
                background-color: #d5d2f5;
                cursor: not-allowed;
            }
        }

        .nextPageButton {
            width: fit-content;
            padding: 0 10px;
        }
    }
}

.table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;

    th {
        padding: 0 10px;
    }

    thead {
        height: 56px;
        color: $dark-font-color;
        font-size: 14px;
    }

    th {
        text-align: left;
    }

    tbody {
        tr {
            width: 100%;
            height: 50px;
            font-size: 14px;
            color: $medium-font-color;
            border-top: 1px solid $light-border-color;
        }
    }
}
