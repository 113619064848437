@import 'src/styles/variables';

.item {
    height: 54px;
    font-weight: 400;
    font-size: 14px;
    border-radius: 6px;

    a {
        padding: 0 14px;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        gap: 10px;
        text-decoration: none;
        color: $dark-font-color;
    }

    &.selected {
        background-color: #7358ff;

        a {
            color: white;
        }

        svg path {
            fill: white;
        }
    }

    svg {
        width: 18px;
        height: 18px;
    }
}
