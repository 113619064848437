.select {
    position: relative;
    display: flex;

    /*
    &:focus .selected {
        border-color: #7358ff;
    }
     */

    .selected {
        background-color: white;
        border: 1px solid #d8dee7;
        height: 40px;
        width: 100%;
        outline: none;
        border-radius: 6px;
        color: #0f2337;
        padding: 12px 8px;
        font-size: 14px;
        display: flex;
        align-items: center;

        &:hover {
            cursor: pointer;
        }

        &.disabled {
            color: #bcbec0 !important;
        }

        &.noBottomRadius {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .list {
        position: absolute;
        list-style: none;
        width: 100%;
        top: 40px;
        border: 1px solid #d8dee7;
        border-top: none;
        border-radius: 0 0 6px 6px;
        overflow: hidden;
        z-index: 100;
        overflow-y: scroll;
        max-height: 200px;

        li {
            background-color: white;
            padding: 14px;
            display: flex;
            flex-direction: column;
            color: #0f2337;
            font-size: 14px;

            &:hover {
                cursor: pointer;
                background-color: #f3f7ff;
            }

            &:not(:last-child) {
                border-bottom: 1px solid #d8dee7;
            }
        }
    }
}
