.allDateInput {
    display: flex;
    border: 2px solid #f1f1f3;
    width: fit-content;
    align-items: center;
    border-radius: 8px;

    &.error {
        border-color: #fa6c6c;
    }

    &.focused {
        border-color: #7358ff !important;
    }

    .slash {
        color: #78869b;
    }

    input {
        border: none;
        outline: none;
        text-align: center;

        &.day,
        &.month {
            width: 30px;
        }
        &.year {
            width: 46px;
        }
    }
}
