.container {
    display: flex;
    gap: 8px;
    height: 40px;
    align-items: center;
    border: 1px solid #d8dee7;
    padding: 4px;
    border-radius: 8px;
    position: relative;
    width: 100%;

    .flag {
        width: 16px;
        height: 11px;
        background-image: url('countries.png');
        background-repeat: no-repeat;
    }

    &.invalid {
        border-color: #fa6c6c;
    }

    &.focused {
        border-color: #7358ff;
    }

    .countryCode {
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        color: #0f2337;

        &:hover {
            cursor: pointer;

            .arrowDown {
                border-top: 6px solid #b7c0cc;
            }
        }

        .arrowDown {
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #d8dee7;
        }
    }

    input.phoneNumber {
        width: 100%;
        border: none;
        outline: none;
        font-size: 14px;
        color: #0f2337;
        background: none;
        border-left: 1px solid #d8dee7;
        padding-left: 8px;
        height: 100%;
    }

    .modalBackground {
        z-index: 10;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: none;
        align-items: center;
        justify-content: center;

        &.shown {
            display: flex;
        }
    }

    .modal {
        height: 400px;
        width: 480px;
        border-radius: 0 0 8px 8px;
        box-shadow: 2px 20px 50px #d3d5dc;
        background-color: white;
        display: flex;
        overflow: hidden;
        flex-direction: column;
        outline: none;

        input.countryInput {
            height: 40px;
            padding: 4px 8px;
            outline: none;
            border: none;
            border-bottom: 1px solid #d3d5dc;
        }

        .listContainer {
            height: 400px;
            overflow: hidden;
            overflow-y: scroll;

            .countryList {
                position: relative;
                display: flex;
                flex-direction: column;
                list-style: none;

                li.countryItem {
                    display: flex;
                    height: 40px;
                    gap: 10px;
                    align-items: center;
                    padding: 0 10px;
                    color: #0f2337;

                    &:hover {
                        background-color: #eceff5;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
