.giftCard {
    width: 320px;
    height: 180px;
    position: relative;
    transition: transform 1.6s;

    &.flip {
        transform: rotateY(180deg);
    }

    .content {
        position: relative;
        width: 100%;
        height: 100%;
        border: 1px solid #fff;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .front {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #0f2337;
        border-radius: 16px;
        padding: 20px;

        .content {
            justify-content: center;
            gap: 20px;

            .logo {
                width: 50px;
            }

            .userEmail {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 26px;
                color: white;
                letter-spacing: 1px;
                font-size: 14px;
                font-family: Consolas;
            }
        }
    }

    .back {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #0f2337;
        border: 2px solid #0f2337;
        border-radius: 16px;
        padding: 20px;
        display: flex;
        justify-content: center;

        .content {
            justify-content: flex-end;

            .code {
                width: calc(100% - 40px);
                margin: 20px;
                height: 26px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 4px 10px;
                background-color: white;
                color: #0f2337;
                font-family: Consolas;
                font-size: 14px;
                transform: rotateY(180deg);
                overflow: hidden;
            }
        }
    }
}
