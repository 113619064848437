@import '../../styles/variables';

.modal {
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    width: 360px;

    span {
        font-size: 14px;
    }

    label {
        font-size: 14px !important;
        font-weight: 500;
    }

    .errorMessage {
        color: $soft-red;
    }

    .row {
        display: flex;
        justify-content: space-between;
    }

    .inputWrapper {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
}
