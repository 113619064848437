@import 'src/styles/variables';

.container {
    color: #0f2337;
    position: relative;
    font-size: 14px;

    .selected {
        background: #f3f7ff;
        padding: 8px 12px;
        width: 100%;
        border-radius: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 50px;

        .clear {
            font-size: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;

            &:hover {
                cursor: pointer;
                color: $soft-red;
            }
        }
    }
}

.input {
    border: 1px solid $light-border-color;
    height: 50px;
    width: 100%;
    outline: none;
    padding: 0 10px;
    border-radius: 6px;
    color: $dark-font-color;

    &.activeSearch {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &:focus {
        border-color: $lavanda;
    }
}

.list {
    position: absolute;
    list-style: none;
    width: 100%;
    top: 50px;
    border: 1px solid $light-border-color;
    border-top: none;
    border-radius: 0 0 6px 6px;
    overflow: hidden;
    max-height: 240px;
    overflow-y: scroll;
    z-index: 99;

    li {
        background-color: white;
        padding: 14px;
        display: flex;
        flex-direction: column;

        &:hover {
            cursor: pointer;
            background-color: #f3f7ff;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $light-border-color;
        }
    }
}
