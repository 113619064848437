.flag-ad {
    background-position: -16px 0;
}

.flag-ae {
    background-position: -32px 0;
}

.flag-af {
    background-position: -48px 0;
}

.flag-ag {
    background-position: -64px 0;
}

.flag-ai {
    background-position: -80px 0;
}

.flag-al {
    background-position: -96px 0;
}

.flag-am {
    background-position: -112px 0;
}

.flag-ao {
    background-position: -144px 0;
}

.flag-ar {
    background-position: -160px 0;
}

.flag-as {
    background-position: -176px 0;
}

.flag-at {
    background-position: -192px 0;
}

.flag-au {
    background-position: -208px 0;
}

.flag-aw {
    background-position: -224px 0;
}

.flag-az {
    background-position: -240px 0;
}

.flag-ba {
    background-position: 0 -11px;
}

.flag-bb {
    background-position: -16px -11px;
}

.flag-bd {
    background-position: -32px -11px;
}

.flag-be {
    background-position: -48px -11px;
}

.flag-bf {
    background-position: -64px -11px;
}

.flag-bg {
    background-position: -80px -11px;
}

.flag-bh {
    background-position: -96px -11px;
}

.flag-bi {
    background-position: -112px -11px;
}

.flag-bj {
    background-position: -128px -11px;
}

.flag-bm {
    background-position: -144px -11px;
}

.flag-bn {
    background-position: -160px -11px;
}

.flag-bo {
    background-position: -176px -11px;
}

.flag-br {
    background-position: -192px -11px;
}

.flag-bs {
    background-position: -208px -11px;
}

.flag-bt {
    background-position: -224px -11px;
}

.flag-bv {
    background-position: -240px -11px;
}

.flag-bw {
    background-position: 0 -22px;
}

.flag-by {
    background-position: -16px -22px;
}

.flag-bz {
    background-position: -32px -22px;
}

.flag-ca {
    background-position: -48px -22px;
}

.flag-cd {
    background-position: -80px -22px;
}

.flag-cf {
    background-position: -96px -22px;
}

.flag-cg {
    background-position: -112px -22px;
}

.flag-ch {
    background-position: -128px -22px;
}

.flag-ci {
    background-position: -144px -22px;
}

.flag-ck {
    background-position: -160px -22px;
}

.flag-cl {
    background-position: -176px -22px;
}

.flag-cm {
    background-position: -192px -22px;
}

.flag-cn {
    background-position: -208px -22px;
}

.flag-co {
    background-position: -224px -22px;
}

.flag-cr {
    background-position: -240px -22px;
}

.flag-cu {
    background-position: 0 -33px;
}

.flag-cv {
    background-position: -16px -33px;
}

.flag-cw {
    background-position: -32px -33px;
}

.flag-cy {
    background-position: -48px -33px;
}

.flag-cz {
    background-position: -64px -33px;
}

.flag-de {
    background-position: -80px -33px;
}

.flag-dj {
    background-position: -96px -33px;
}

.flag-dk {
    background-position: -112px -33px;
}

.flag-dm {
    background-position: -128px -33px;
}

.flag-do {
    background-position: -144px -33px;
}

.flag-dz {
    background-position: -160px -33px;
}

.flag-ec {
    background-position: -176px -33px;
}

.flag-ee {
    background-position: -192px -33px;
}

.flag-eg {
    background-position: -208px -33px;
}

.flag-eh {
    background-position: -224px -33px;
}

.flag-er {
    background-position: 0 -44px;
}

.flag-es {
    background-position: -16px -44px;
}

.flag-et {
    background-position: -32px -44px;
}

.flag-eu {
    background-position: -48px -44px;
}

.flag-fi {
    background-position: -64px -44px;
}

.flag-fj {
    background-position: -80px -44px;
}

.flag-fk {
    background-position: -96px -44px;
}

.flag-fm {
    background-position: -112px -44px;
}

.flag-fo {
    background-position: -128px -44px;
}

.flag-fr {
    background-position: -144px -44px;
}

.flag-ga {
    background-position: -160px -44px;
}

.flag-gb {
    background-position: -176px -44px;
}

.flag-gd {
    background-position: -192px -44px;
}

.flag-ge {
    background-position: -208px -44px;
}

.flag-gf {
    background-position: -224px -44px;
}

.flag-gg {
    background-position: -240px -44px;
}

.flag-gh {
    background-position: 0 -55px;
}

.flag-gi {
    background-position: -16px -55px;
}

.flag-gl {
    background-position: -32px -55px;
}

.flag-gm {
    background-position: -48px -55px;
}

.flag-gn {
    background-position: -64px -55px;
}

.flag-gp {
    background-position: -80px -55px;
}

.flag-gq {
    background-position: -96px -55px;
}

.flag-gr {
    background-position: -112px -55px;
}

.flag-gs {
    background-position: -128px -55px;
}

.flag-gt {
    background-position: -144px -55px;
}

.flag-gu {
    background-position: -160px -55px;
}

.flag-gw {
    background-position: -176px -55px;
}

.flag-gy {
    background-position: -192px -55px;
}

.flag-hk {
    background-position: -208px -55px;
}

.flag-hm {
    background-position: -224px -55px;
}

.flag-hn {
    background-position: -240px -55px;
}

.flag-hr {
    background-position: 0 -66px;
}

.flag-ht {
    background-position: -16px -66px;
}

.flag-hu {
    background-position: -32px -66px;
}

.flag-id {
    background-position: -64px -66px;
}

.flag-ie {
    background-position: -80px -66px;
}

.flag-il {
    background-position: -96px -66px;
}

.flag-im {
    background-position: -112px -66px;
}

.flag-in {
    background-position: -128px -66px;
}

.flag-io {
    background-position: -144px -66px;
}

.flag-iq {
    background-position: -160px -66px;
}

.flag-ir {
    background-position: -176px -66px;
}

.flag-is {
    background-position: -192px -66px;
}

.flag-it {
    background-position: -208px -66px;
}

.flag-je {
    background-position: -224px -66px;
}

.flag-jm {
    background-position: -240px -66px;
}

.flag-jo {
    background-position: 0 -77px;
}

.flag-jp {
    background-position: -16px -77px;
}

.flag-ke {
    background-position: -32px -77px;
}

.flag-kg {
    background-position: -48px -77px;
}

.flag-kh {
    background-position: -64px -77px;
}

.flag-ki {
    background-position: -80px -77px;
}

.flag-km {
    background-position: -96px -77px;
}

.flag-kn {
    background-position: -112px -77px;
}

.flag-kp {
    background-position: -128px -77px;
}

.flag-kr {
    background-position: -144px -77px;
}

.flag-kw {
    background-position: -176px -77px;
}

.flag-ky {
    background-position: -192px -77px;
}

.flag-kz {
    background-position: -208px -77px;
}

.flag-la {
    background-position: -224px -77px;
}

.flag-lb {
    background-position: -240px -77px;
}

.flag-lc {
    background-position: 0 -88px;
}

.flag-li {
    background-position: -16px -88px;
}

.flag-lk {
    background-position: -32px -88px;
}

.flag-lr {
    background-position: -48px -88px;
}

.flag-ls {
    background-position: -64px -88px;
}

.flag-lt {
    background-position: -80px -88px;
}

.flag-lu {
    background-position: -96px -88px;
}

.flag-lv {
    background-position: -112px -88px;
}

.flag-ly {
    background-position: -128px -88px;
}

.flag-ma {
    background-position: -144px -88px;
}

.flag-mc {
    background-position: -160px -88px;
}

.flag-md {
    background-position: -176px -88px;
}

.flag-me {
    background-position: -192px -88px;
}

.flag-mg {
    background-position: -208px -88px;
}

.flag-mh {
    background-position: -224px -88px;
}

.flag-mk {
    background-position: -240px -88px;
}

.flag-ml {
    background-position: 0 -99px;
}

.flag-mm {
    background-position: -16px -99px;
}

.flag-mn {
    background-position: -32px -99px;
}

.flag-mo {
    background-position: -48px -99px;
}

.flag-mp {
    background-position: -64px -99px;
}

.flag-mq {
    background-position: -80px -99px;
}

.flag-mr {
    background-position: -96px -99px;
}

.flag-ms {
    background-position: -112px -99px;
}

.flag-mt {
    background-position: -128px -99px;
}

.flag-mu {
    background-position: -144px -99px;
}

.flag-mv {
    background-position: -160px -99px;
}

.flag-mw {
    background-position: -176px -99px;
}

.flag-mx {
    background-position: -192px -99px;
}

.flag-my {
    background-position: -208px -99px;
}

.flag-mz {
    background-position: -224px -99px;
}

.flag-na {
    background-position: -240px -99px;
}

.flag-nc {
    background-position: 0 -110px;
}

.flag-ne {
    background-position: -16px -110px;
}

.flag-nf {
    background-position: -32px -110px;
}

.flag-ng {
    background-position: -48px -110px;
}

.flag-ni {
    background-position: -64px -110px;
}

.flag-nl {
    background-position: -80px -110px;
}

.flag-no {
    background-position: -96px -110px;
}

.flag-np {
    background-position: -112px -110px;
}

.flag-nr {
    background-position: -128px -110px;
}

.flag-nu {
    background-position: -144px -110px;
}

.flag-nz {
    background-position: -160px -110px;
}

.flag-om {
    background-position: -176px -110px;
}

.flag-pa {
    background-position: -192px -110px;
}

.flag-pe {
    background-position: -208px -110px;
}

.flag-pf {
    background-position: -224px -110px;
}

.flag-pg {
    background-position: -240px -110px;
}

.flag-ph {
    background-position: 0 -121px;
}

.flag-pk {
    background-position: -16px -121px;
}

.flag-pl {
    background-position: -32px -121px;
}

.flag-pm {
    background-position: -48px -121px;
}

.flag-pn {
    background-position: -64px -121px;
}

.flag-pr {
    background-position: -80px -121px;
}

.flag-ps {
    background-position: -96px -121px;
}

.flag-pt {
    background-position: -112px -121px;
}

.flag-pw {
    background-position: -128px -121px;
}

.flag-py {
    background-position: -144px -121px;
}

.flag-qa {
    background-position: -160px -121px;
}

.flag-re {
    background-position: -176px -121px;
}

.flag-ro {
    background-position: -192px -121px;
}

.flag-rs {
    background-position: -208px -121px;
}

.flag-ru {
    background-position: -224px -121px;
}

.flag-rw {
    background-position: -240px -121px;
}

.flag-sa {
    background-position: 0 -132px;
}

.flag-sb {
    background-position: -16px -132px;
}

.flag-sc {
    background-position: -32px -132px;
}

.flag-sd {
    background-position: -64px -132px;
}

.flag-se {
    background-position: -80px -132px;
}

.flag-sg {
    background-position: -96px -132px;
}

.flag-sh {
    background-position: -112px -132px;
}

.flag-si {
    background-position: -128px -132px;
}

.flag-sk {
    background-position: -144px -132px;
}

.flag-sl {
    background-position: -160px -132px;
}

.flag-sm {
    background-position: -176px -132px;
}

.flag-sn {
    background-position: -192px -132px;
}

.flag-so {
    background-position: -208px -132px;
}

.flag-sr {
    background-position: -240px -132px;
}

.flag-ss {
    background-position: 0 -143px;
}

.flag-st {
    background-position: -16px -143px;
}

.flag-sv {
    background-position: -32px -143px;
}

.flag-sx {
    background-position: -48px -143px;
}

.flag-sy {
    background-position: -64px -143px;
}

.flag-sz {
    background-position: -80px -143px;
}

.flag-tc {
    background-position: -96px -143px;
}

.flag-td {
    background-position: -112px -143px;
}

.flag-tf {
    background-position: -128px -143px;
}

.flag-tg {
    background-position: -144px -143px;
}

.flag-th {
    background-position: -160px -143px;
}

.flag-tj {
    background-position: -176px -143px;
}

.flag-tk {
    background-position: -192px -143px;
}

.flag-tl {
    background-position: -208px -143px;
}

.flag-tm {
    background-position: -224px -143px;
}

.flag-tn {
    background-position: -240px -143px;
}

.flag-to {
    background-position: 0 -154px;
}

.flag-tr {
    background-position: -16px -154px;
}

.flag-tt {
    background-position: -32px -154px;
}

.flag-tv {
    background-position: -48px -154px;
}

.flag-tw {
    background-position: -64px -154px;
}

.flag-tz {
    background-position: -80px -154px;
}

.flag-ua {
    background-position: -96px -154px;
}

.flag-ug {
    background-position: -112px -154px;
}

.flag-um {
    background-position: -128px -154px;
}

.flag-us {
    background-position: -144px -154px;
}

.flag-uy {
    background-position: -160px -154px;
}

.flag-uz {
    background-position: -176px -154px;
}

.flag-va {
    background-position: -192px -154px;
}

.flag-vc {
    background-position: -208px -154px;
}

.flag-ve {
    background-position: -224px -154px;
}

.flag-vg {
    background-position: -240px -154px;
}

.flag-vi {
    background-position: 0 -165px;
}

.flag-vn {
    background-position: -16px -165px;
}

.flag-vu {
    background-position: -32px -165px;
}

.flag-wf {
    background-position: -64px -165px;
}

.flag-ws {
    background-position: -80px -165px;
}

.flag-ye {
    background-position: -96px -165px;
}

.flag-yt {
    background-position: -112px -165px;
}

.flag-za {
    background-position: -128px -165px;
}

.flag-zm {
    background-position: -160px -165px;
}

.flag-zw {
    background-position: -176px -165px;
}
