@import 'src/styles/variables';

.card {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border: 1px solid #d8dee7;
    border-radius: 8px;
    min-width: 200px;
    background-color: white;
    flex: 1;

    header {
        display: flex;
        justify-content: space-between;

        .iconContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 8px;
            background-color: #f8f3ff;
            user-select: none;
        }

        .deviationContainer {
            display: flex;
            align-items: center;
            gap: 5px;

            b {
                font-size: 14px;
                color: #07af25;

                &.positive {
                    color: #07af25;
                }

                &.negative {
                    color: #e75c5c;
                }
            }
        }
    }

    label {
        color: $medium-font-color;
        font-size: 14px;
        font-weight: 300;
        margin: 20px 0 10px 0;
    }

    .value {
        color: $dark-font-color;
        font-size: 18px;
    }
}
