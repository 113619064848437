.toast {
    width: fit-content;
    position: fixed;
    bottom: -100px;
    left: calc(50% + 240px);
    transition: 1s;
    transform: translateX(calc(-50% - 120px));
    background-color: #0f2337;
    color: white;
    display: flex;
    gap: 10px;
    padding: 14px;
    border-radius: 4px;
    align-items: center;

    &.shown {
        bottom: 20px;
    }
}
