@import 'styles/variables';

.flatButton {
    background: none;
    outline: none;
    border: none;
    border-radius: 6px;
    height: fit-content;
    padding: 6px 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: $box-shadow-with-border;
    color: $dark-font-color;
}
