@import 'src/styles/variables';

.view {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fbfbfd;
    padding: 0 20px 40px 20px;
    gap: 14px;

    .container {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .noCompany {
            font-size: 24px;
            color: $medium-font-color;
        }

        .noContact {
            font-size: 16px;
            color: $medium-font-color;
        }

        label {
            font-weight: 500;
            font-size: 15px;
        }

        input {
            height: 28px;
            padding: 0 10px;
            border-radius: 4px;
            border: 1px solid #ddd;
            font-size: 15px;
        }

        .row {
            display: flex;
            gap: 10px;
            align-items: center;
        }
    }

    .userList {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 14px;

        li {
            display: flex;
            flex-direction: column;
            gap: 3px;

            .name {
                font-size: 16px;
                color: $dark-font-color;
                font-weight: 500;
            }
            .metadata {
                font-size: 14px;
                color: $medium-font-color;
            }
        }
    }
}
