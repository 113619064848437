@import 'src/styles/variables';

.wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 14px;
    border-radius: 6px;
    background-color: white;
    border: 1px solid $light-border-color;

    .item {
        display: flex;
        flex-direction: column;

        label {
            color: $disabled-color;
            font-size: 12px;
        }

        b {
            font-size: 24px;
        }
    }
}
