@import 'src/styles/variables';

.view {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fbfbfd;
    padding: 0 20px 40px 20px;
    gap: 14px;

    .mainButtonsContainer {
        width: 100%;
        display: flex;
        gap: 20px;
    }

    .bulkUploadModal {
        padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;

        span {
            font-size: 14px;
        }

        label {
            font-size: 14px;
            font-weight: 500;
        }

        .errorMessage {
            color: $soft-red;
        }
    }
}
